<template>
  <div class="h-full">
    <yb-top-nav :active="false" :start="true" />
    <NuxtPage />
  </div>
</template>

<script>
import YbTopNav from "@/layouts/_topnav.vue"

export default {
  name: "Start",
  components: { YbTopNav }
}
</script>
